import React, {
  createContext,
  useEffect,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import GlobalStyles from "./GlobalStyles";
import Homepage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import CompetitionPage from "./pages/CompetitionPage";
import CompetitionDetailsPage from "./pages/CompetitionDetailsPage";
import AppBarComponent from "./components/AppBarComponent";
import GlobalJackpotPage from "./pages/GlobalJackpotPage";
import FloatingShapes from "./components/FloatingShapes";
import DashboardPage from "./pages/Dashboardpage";
import styled from "styled-components";
import {
  WalletProvider,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl, Connection, PublicKey } from "@solana/web3.js";
import { Program, AnchorProvider } from "@coral-xyz/anchor";
import idl from "./blind_faith_program.json"; // Adjust path if necessary
import { ThemeContext } from "./context/ThemeContext";
import { AdminProvider } from "./context/AdminContext";
import { AnimatePresence, motion } from "framer-motion";
import "@solana/wallet-adapter-react-ui/styles.css";
import { Buffer } from "buffer";
import { useWallet } from "@solana/wallet-adapter-react";
import { ErrorProvider } from "./context/ErrorContext";
import { AlertProvider } from "./context/AlertContext";
import NotificationProvider from "./context/NotificationContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

globalThis.Buffer = Buffer;

// Initialize the context
const ProgramContext = createContext(null);

export const useProgram = () => {
  const context = useContext(ProgramContext);
  if (!context) {
    throw new Error("useProgram must be used within ProgramProvider");
  }
  return context;
};

// Styling
const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  flex: 1;
  overflow-y: auto;
  margin-top: 5%;
`;

// AppContent handles routing
const AppContent = () => {
  const location = useLocation();
  const [marginTop, setMarginTop] = useState("5%");

  useEffect(() => {
    const handleResize = () => {
      // Dynamically set margin-top based on screen width
      const newMarginTop =
        window.innerWidth <= 768
          ? "15%"
          : window.innerWidth <= 1024
          ? "10%"
          : "5%";
      setMarginTop(newMarginTop);
    };

    handleResize(); // Run initially
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <AppBarComponent />
      <div
        style={{
          flex: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <FloatingShapes />
        <ContentWrapper style={{ marginTop }}>
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
              <Route
                path="/"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.1, ease: "easeInOut" }}
                  >
                    <Homepage />
                  </motion.div>
                }
              />
              <Route
                path="/create-competition"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.1, ease: "easeInOut" }}
                  >
                    <CompetitionPage />
                  </motion.div>
                }
              />
              <Route
                path="/competition/:id"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.1, ease: "easeInOut" }}
                  >
                    <CompetitionDetailsPage />
                  </motion.div>
                }
              />
              <Route
                path="/profile/:wallet_address"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.1, ease: "easeInOut" }}
                  >
                    <ProfilePage />
                  </motion.div>
                }
              />
              <Route
                path="/global-jackpot"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.1, ease: "easeInOut" }}
                  >
                    <GlobalJackpotPage />
                  </motion.div>
                }
              />
              <Route
                path="/admin-dashboard"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.1, ease: "easeInOut" }}
                  >
                    <DashboardPage />
                  </motion.div>
                }
              />
            </Routes>
          </AnimatePresence>
        </ContentWrapper>
      </div>
    </div>
  );
};

// This component handles setting up the program provider inside the WalletProvider
const ProgramProviderComponent = () => {
  const { theme } = useContext(ThemeContext);
  const { publicKey, signTransaction } = useWallet();
  const network = clusterApiUrl("devnet");

  const programProviderValue = useMemo(() => {
    const connection = new Connection(network, "confirmed");
    const programId = new PublicKey(process.env.REACT_APP_PROGRAM_ID);

    let walletInterface;
    if (publicKey && signTransaction) {
      walletInterface = { publicKey, signTransaction };
    } else {
      walletInterface = {
        publicKey: null,
        signTransaction: async (tx) => tx,
        signAllTransactions: async (txs) => txs,
      };
    }

    const provider = new AnchorProvider(
      connection,
      walletInterface,
      AnchorProvider.defaultOptions()
    );

    const program = new Program(idl, programId);

    return { provider, program };
  }, [network, publicKey, signTransaction]);

  return (
    <ProgramContext.Provider value={programProviderValue}>
      <StyledThemeProvider theme={theme}>
        <ErrorProvider>
          <AlertProvider>
            <NotificationProvider walletAddress={publicKey?.toBase58()}>
              <ToastContainer position="top-right" autoClose={5000} />
              <GlobalStyles />
              <Router>
                <AppContent />
              </Router>
            </NotificationProvider>
          </AlertProvider>
        </ErrorProvider>
      </StyledThemeProvider>
    </ProgramContext.Provider>
  );
};

// Main App component
const App = () => {
  const network = clusterApiUrl("devnet");
  const wallets = [new PhantomWalletAdapter(), new SolflareWalletAdapter()];

  return (
    <ConnectionProvider endpoint={network}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <AdminProvider>
            <ProgramProviderComponent />
          </AdminProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
